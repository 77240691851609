* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root, .App {
  font-family: Arial, sans-serif;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.content {
  background: linear-gradient(180deg, #105040 0%, #051218 100%);
  color: white;
  overflow-y: auto;
}

.content.expanded nav{
  width: 80px;
}

.section-name {
  padding: 20px;;
}