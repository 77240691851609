.separator {
  position: relative;
  width: 100%;
  height: 50px;
  overflow: hidden;
  background: linear-gradient(99deg, #c6fca6 0%, rgba(167, 252, 238, 0.74) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrolling-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-left 12s linear infinite;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 500px) {
  .scrolling-text {
    font-size: 16px;
    animation: scroll-left 6s linear infinite;
  }
}