#project-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  justify-content: center;
  padding: 20px;
}

.project-item {
  overflow: hidden;
  transition: transform 0.3s ease;
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 1), 
              0 0 4px rgba(0, 0, 0, 1);
}

.project-item:hover {
  transform: translateY(-5px);
}

.project-cover {
  margin: 4px;
  width: calc(100% - 8px);
  height: 180px;
  border: 1px solid gray;
  object-fit: cover; 
}

.project-title, .project-description {
  margin: 8px 0;
}

.project-content {
  margin: 8px;
}

.project-stack {
  display: flex;
  flex-direction: row;
  margin: 12px 0px;
  gap: 4px;
  flex-wrap: wrap;
}

.project-stack-item {
  border: 1px solid gray;
  color: black;
  background-color: #ddd;
  border-radius: 8px;
  width: fit-content;
  padding: 8px;
}

.project-links {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  gap: 8px;
  flex-wrap: wrap;
}

.project-link-container {
  border: 1px solid gray;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  padding: 8px;
}

.project-link-container:hover {
  background-color: black;
  color: white;
}

.project-link {
  color: white;
  text-decoration: none;
  padding: 8px;
}

.project-icon {
  padding-right: 8px;
}