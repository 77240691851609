
#introduction {
  display: flex;
  flex-direction: row;
  background-color: rgb(7, 25, 21);
}

#intro-info {
  flex: 3;
  padding: 64px;
  display: flex;
  flex-direction: column;
}

#intro-photo-container {
  flex: 1;
}

#intro-photo {
  height: 480px;
  padding: 32px;
}

.intro-info-text {
  margin: 8px 0 0;
}

.intro-links {
  display: flex;
  gap: 16px;
}

.intro-link-container {
  border: 1px solid green;
  border-radius: 50%;
  padding: 12px;
}

.link-icon {
  width: 32px;
  height: 32px;
  color: white;
}

.green {
  color: green;
}

.green-button {
  margin: 16px 0;
  padding: 12px 24px;
  background-color: green;
  color: white;
  border: 1px solid darkgreen;
  border-radius: 8px;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
}

#intro-description p{
  margin: 8px 0;
}
#intro-description {
  margin: 8px 0;
}

@media screen and (max-width: 900px) {
  #intro-photo-container {
    padding: 8px;
    display: flex;
    justify-content: center;    
  }
  #intro-photo {
    height: 320px;
    padding: 16px;
  }

  #intro-info {
    padding: 16px 8px;
  }
}

@media screen and (max-width: 600px) {
  #intro-photo-container {
    padding: 8px;
    display: flex;
    justify-content: center;    
  }
  #intro-photo {
    height: 240px;
    margin: auto;
    padding: 8px;
  }
  #intro-info {
    padding: 8px 4px;
  }
  #intro-info h1{
    font-size: 24px;
  }
  #intro-info h3{
    font-size: 16px;
  }
  #intro-description {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  #introduction {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  #intro-photo-container {
    padding-bottom: 0;
  }
}